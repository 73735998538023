import { Injectable } from "@angular/core";
import { CustomerDocument } from "../model/customer-document";

@Injectable()
export class FilterService {

  static readonly filterPredicate = (customerDocument: CustomerDocument, filterStr: string): boolean => {
    const filterToUse = filterStr.trim().toLowerCase();

    const customerInfo = [
      customerDocument.customerName,
      customerDocument.customerNumber,
    ].join(" ").toLowerCase();

    const documentName = customerDocument.document.name?.toLowerCase() || "";
    const metaData = Object.values(customerDocument.document.metaData).join(" ").toLowerCase();

    return (
      customerInfo.includes(filterToUse) ||
            documentName.includes(filterToUse) ||
            metaData.includes(filterToUse)
    );
  };
}