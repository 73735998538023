export const DE_TRANSLATIONS = {
  SERVICE_NAME: "Dokumente Admin",
  OVERVIEW: {
    TITLE: "Admin-Bereich Dokumente",
    SUBTITLE:
         "Administrationsbereich für alle Dokumente des Kunden mit der BKW: Verträge, EVU-Vorlagen, IT@AÄB Dokumente",
    CONTRACTS: "Verträge",
    CONTRACTS_SUBTITLE: "Aktive und erfüllte Verträge",
    CONTRACTS_LINK: "Zu den Verträgen",
    NET_USAGE: "Netznutzung",
    NET_USAGE_SUBTITLE: "Netznutzungsdokumente",
    NET_USAGE_LINK: "Zu den Netznutzungsdokumenten",
    DOCHUB_ADMIN: "Administration Collections / Dokumente",
    DOCHUB_ADMIN_SUBTITLE: "Dokument Collections und Dokumente administrieren",
    DOCHUB_ADMIN_LINK: "Zu der DocHub Administration",
    B2B_EXPORT: "OP B2B Export",
    B2B_EXPORT_SUBTITLE: "B2B Export-Dateien aus dem OP Admintool.",
    B2B_EXPORT_LINK: "Zu den Exports",
    DSV: {
      MARKET_REPORTS: {
        TITLE: "Marktberichte",
        SUBTITLE: "Marktberichte, welche dem Kunden bei den Marktinformationen zur Verfügung gestellt werden.",
        LINK: "Zu den Dokumenten",
      },
    },
  },
  CONTRACTS: {
    LIST: {
      COLUMNS: {
        CUSTOMER_NAME: "Kundenname",
        CUSTOMER_NUMBER: "Kundennummer",
        QUOTE_NUMBER: "Angebotsnummer",
        CONTRACT_NUMBER: "Vertragsnummer",
        NAME: "Name",
        START: "Vertragsbeginn",
        END: "Vertragsende",
        STATUS: "Status",
        DOWNLOAD: "Download",
        EDIT: "Vertragsdaten bearbeiten",
        DELETE: "Vertrag löschen",
      },
      SEARCH_CUSTOMER: "Kunde suchen",
      SEARCH: "Dokumente suchen",
      EDIT_SUCCESSFUL: "Der Vertrag wurde erfolgreich aktualisiert.",
      EDIT_SUCCESSFUL_CRM:
        "Der Vertrag wurde erfolgreich aktualisiert. Die Vertragsanpassungen werden nicht ins CRM synchronisiert.",
      EDIT_ERROR: "Der Vertrag konnte nicht aktualisiert werden.",
      DELETE_SUCCESSFUL: "Der Vertrag wurde erfolgreich gelöscht.",
      DELETE_SUCCESSFUL_CRM:
        "Der Vertrag wurde erfolgreich gelöscht. Die Vertragsanpassungen werden nicht ins CRM synchronisiert.",
      DELETE_ERROR: "Der Vertrag konnte nicht gelöscht werden.",
      STATUS: {
        PLACEHOLDER: "Status",
        ACTIVE: "Aktiv",
        FULFILLED: "Erfüllt",
        ALL: "Alle anzeigen",
      },
      NO_DATA: "Für die Suche wurden keine Dokumente gefunden.",
    },
    UPLOAD: {
      BUTTON: "Vertrag hochladen",
      TOOLTIP: "Sie müssen erstmal einen Kunden suchen und auswählen",
      FILE_READING_ERROR: "Lesen der ausgewählten Datei ist fehlgeschlagen.",
      UPLOAD_ERROR: "Der Vertrag konnte nicht hochgeladen werden.",
      UPLOAD_SUCCESSFUL: "Der Vertrag wurde erfolgreich hochgeladen.",
      SHARE_POINT_PARAMS_INVALID: "Die gelieferten SharePoint Parameter sind invalide.",
      DIALOG: {
        TITLE: {
          UPLOAD: "Vertrag hochladen",
          EDIT: "Vertrag bearbeiten",
        },
        CUSTOMER: "Kunde",
        SUBMIT: "Ausführen",
        CANCEL: "Abbrechen",
        SELECT_FILE: "Datei auswählen",
        NO_FILE_SELECTED: "Keine Datei ausgewählt",
      },
    },
    CONFIRMATION: {
      DELETE: {
        TITLE: "Vertrag löschen",
        DESCRIPTION: "Wollen Sie den Vertrag wirklich löschen?",
      },
    },
  },
  B2B_EXPORT: {
    TITLE: "B2B Export",
    SEARCH: "Suchbegriff eingeben",
    CONFIRMATION: {
      DELETE: {
        TITLE: "B2B Export löschen",
        DESCRIPTION: "Wollen Sie den B2B Export wirklich löschen?",
      },
    },
    LIST: {
      DELETE_SUCCESSFUL: "Der B2B Export wurde erfolgreich gelöscht.",
      DELETE_ERROR: "Der B2B Export konnte nicht gelöscht werden.",
      COLUMNS: {
        NAME: "Name",
        MUT_DATE: "Aktualisiert",
        CREATED_DATE: "Hochgeladen",
        DOWNLOAD: "Download",
        DELETE: "B2B Export löschen",
      },
    },
  },
  MARKET_REPORTS: {
    DSV_MARKET_REPORT_TYPE: "Art des Marktberichts",
    DSV_MARKET_REPORT_TYPES: {
      KMU_REPORT: "Monatlicher Marktreport",
      WEEKLY_REPORT: "Wochenmarktbericht",
      MONTHLY_RADAR: "Monatsradar Energiepreise",
    },
    LIST: {
      COLUMNS: {
        NAME: "Name",
        CREATED_ON: "Hochgeladen am",
        DOWNLOAD: "Herunterladen",
        DELETE: "Löschen",
      },
      DELETE_ERROR: "Der Marktbericht konnte nicht gelöscht werden.",
      DELETE_SUCCESS: "Der Marktbericht wurde gelöscht.",
    },
    UPLOAD: {
      BUTTON: "Marktbericht hochladen",
      DIALOG: {
        TITLE: "Marktbericht",
        CANCEL: "Abbrechen",
        SUBMIT: "Hochladen",
        SELECT_FILE: "Datei auswählen",
        NO_FILE_SELECTED: "Keine Datei ausgewählt",
      },
      UPLOAD_SUCCESSFUL: "Der Marktbericht wurde erfolgreich hochgeladen.",
      UPLOAD_ERROR: "Der Marktbericht konnte nicht hochgeladen werden.",
      FILE_READING_ERROR: "Der Marktbericht konnte nicht hochgeladen werden.",
    },
    CONFIRMATION: {
      DELETE: {
        TITLE: "Marktbericht löschen",
        DESCRIPTION: "Wollen sie den Marktbericht wirklich löschen?",
      },
    },
    INVALID_DATE: "Ungültiges Datum",
  },
  DIALOG: {
    BUTTON: {
      SUBMIT: "Ja",
      CANCEL: "Abbrechen",
    },
  },
  NET_USAGE: {
    CONTRACTS_LABEL: "Verträge",
    CONTRACTS: {
      LIST: {
        COLUMNS: {
          CONTRACT_NUMBER: "Vertragsnummer",
          CONTRACT_NAME: "Vertragsname",
          CONNECTION_OBJECT: "Anschlussobjekt",
          OWNER: "Eigentümer",
          START: "Vertragsstartdatum",
          DOWNLOAD: "Herunterladen",
          DELETE: "Löschen",
        },
        SEARCH_OWNER: "Eigentümer suchen",
      },
    },
    CONNECTION_OBJECTS_LABEL: "Anschlussobjekte",
    CONNECTION_OBJECTS: {
      LIST: {
        COLUMNS: {
          NAME: "Dateiname",
          OWNER: "Eigentümer",
          CREATED: "Erstellt am",
          DOWNLOAD: "Herunterladen",
          EDIT: "Anschlussobjektdokumentdaten bearbeiten",
          DELETE: "Löschen",
        },
        SEARCH_OWNER: "Eigentümer suchen",
        EDIT_SUCCESSFUL: "Das Anschlussobjektdokument wurde erfolgreich aktualisiert.",
        EDIT_SUCCESSFUL_CRM:
          "Das Anschlussobjektdokument wurde erfolgreich aktualisiert. Die Das Anschlussobjektdokumentanpassungen werden nicht ins CRM synchronisiert.",
        EDIT_ERROR: "Das Anschlussobjektdokument konnte nicht aktualisiert werden.",
        DELETE_SUCCESSFUL: "Das Anschlussobjektdokument wurde erfolgreich gelöscht.",
        DELETE_SUCCESSFUL_CRM:
          "Das Anschlussobjektdokument wurde erfolgreich gelöscht. Die Anschlussobjektdokumentanpassungen werden nicht ins CRM synchronisiert.",
        DELETE_ERROR: "Das Anschlussobjektdokument konnte nicht gelöscht werden.",
      },
      UPLOAD: {
        UPLOAD_ERROR: "Das Anschlussobjektdokument konnte nicht hochgeladen werden.",
        UPLOAD_SUCCESSFUL: "Das Anschlussobjektdokument wurde erfolgreich hochgeladen.",
      },
      DIALOG: {
        TITLE: {
          UPLOAD: "Anschlussobjektdokument hochladen",
          EDIT: "Anschlussobjektdokument bearbeiten",
        },
      },
      CONFIRMATION: {
        DELETE: {
          TITLE: "Anschlussobjektdokument löschen",
          DESCRIPTION: "Wollen Sie das Anschlussobjektdokument wirklich löschen?",
        },
      },
    },
    OWNER: "Eigentümer",
  },
  DOCHUB_ADMIN: {
    DOCUMENT_COLLECTIONS_LABEL: "Collections",
    DOCUMENTS_LABEL: "Dokumente",
    DOCUMENT_COLLECTIONS: {
      LIST: {
        COLUMNS: {
          COLLECTION_NAME: "Name",
          CUSTOMER_NUMBER: "Kundennummer",
          EDIT_DATA: "Daten anpassen",
          EDIT_PERMISSIONS: "Berechtigungen anpassen",
        },
        SEARCH_COLLECTION: "Collection suchen",
        MULTIPLE_EDIT_PERMISSIONS: "Berechtigungen der ausgewählten Collections anpassen",
        COUNT_SELECTED_COLLECTIONS: "Anzahl ausgewählter Collections: {{number}}",
      },
    },
    DOCUMENTS: {
      LIST: {
        SEARCH_CUSTOMER: "Kunde/Eigentümer suchen",
        SEARCH_DOCUMENT: "Dokument suchen",
        SEARCH_PERMISSION: "Berechtigung suchen (mit Enter Suche starten)",
        MULTIPLE_EDIT_PERMISSIONS: "Berechtigungen der ausgewählten Dokumente anpassen",
        COUNT_SELECTED_DOCUMENTS: "Anzahl ausgewählter Dokumente: {{number}}",
        COLUMNS: {
          NAME: "Name",
          LANGUAGE: "Sprache",
          CUSTOMER: "Kunde/Eigentümer",
          TYPE: "Dokumenttyp",
          FILE_NAME: "Dateiname",
          FILE_TYPE: "Dateityp",
        },
      },
    },
    EDIT: {
      EDIT_DATA: "Daten anpassen",
      EDIT_PERMISSIONS: "Berechtigungen anpassen",
      DOCUMENT: "Dokument",
      META_DATA: "MetaData",
      CUSTOMER_NUMBER: "customerNumber",
      ADD_PERMISSION: "Berechtigung hinzufügen",
      EDIT_PERMISSION: "Berechtigung editieren",
      EDIT_DATA_ERROR: "Die Dokument-Collection Daten konnten nicht aktualisiert werden.",
      EDIT_DATA_SUCCESSFUL: "Die Dokument-Collection Daten wurden erfolgreich aktualisiert.",
      EDIT_DATA_DOCUMENT_ERROR: "Die Dokument Daten konnten nicht aktualisiert werden.",
      EDIT_DATA_DOCUMENT_SUCCESSFUL: "Die Dokument Daten wurden erfolgreich aktualisiert.",
      EDIT_PERMISSIONS_ERROR: "Die Dokument-Collection Berechtigungen konnten nicht aktualisiert werden.",
      EDIT_PERMISSIONS_SUCCESSFUL: "Die Dokument-Collection Berechtigungen wurden erfolgreich aktualisiert.",
      EDIT_PERMISSIONS_DOCUMENT_ERROR: "Die Dokument Berechtigungen konnten nicht aktualisiert werden.",
      EDIT_PERMISSIONS_DOCUMENT_SUCCESSFUL: "Die Dokument Berechtigungen wurden erfolgreich aktualisiert.",
      PERMISSIONS: {
        IDENTITY: "Identity",
        IDENTITY_TYPE: "Identity Type",
        READ: "Read",
        WRITE: "Write",
        CHANGE_PERMISSION: "Change Permission",
        INHERITABLE: "Inheritable",
        IS_INHERITED: "Is Inherited",
        EDIT: "Editieren",
        NOTE: "HINWEIS: Nur Gemeinsame Berechtigungen sind angezeigt",
        CONFIRMATION: {
          TITLE: "Berechtigung löschen",
          DESCRIPTION: "Wollen Sie die Berechtigung wirklich entfernen?",
        },
      },
      ERROR: {
        LOAD_ERROR_COLLECTION_PERMISSIONS: "Die Dokument-Collection Berechtigungen konnten nicht geladen werden.",
        LOAD_ERROR_DOCUMENT_PERMISSIONS: "Die Dokument Berechtigungen konnten nicht geladen werden.",
      },
    },
  },
  UPLOAD: {
    SUBMIT: "Ausführen",
    SAVE: "Speichern",
    ADD: "Hinzufügen",
    CANCEL: "Abbrechen",
    SELECT_FILE: "Datei auswählen",
    NO_FILE_SELECTED: "Keine Datei ausgewählt",
  },
  ERROR: {
    LOAD_ERROR_UNAUTHORIZED: "Sie haben nicht die passende Berechtigung, um die Applikation zu verwenden.",
    OPERATION_ERROR_UNAUTHORIZED: "Sie haben nicht die passende Berechtigung, um diese Operation auszuführen.",
    LOAD_ERROR_DOCUMENTS: "Die Dokumente konnten nicht oder nur zum Teil geladen werden.",
    LOAD_ERROR_COLLECTIONS: "Die Dokument-Collections konnten nicht geladen werden.",
    FILE_DOWNLOAD_ERROR: "Das Dokument konnte nicht heruntergeladen werden.",
    COLLECTION_CREATE: {
      GENERAL: "Eine neue Dokument-Collection konnte nicht erstellt werden. Ein unbekannter Fehler ist aufgetreten.",
      NOT_FOUND:
        "Eine neue Dokument-Collection konnte nicht erstellt werden. Es wurde keine Organisation mit der CRM ID {{crmNumber}} gefunden.",
      CONFLICT:
        "Eine neue Dokument-Collection konnte nicht erstellt werden. Für die Organisation mit der CRM ID {{crmNumber}} existiert bereits eine Collection.",
      UNAUTHORIZED:
        "Eine neue Dokument-Collection konnte nicht erstellt werden. Sie haben nicht die passende Berechtigung, um diese Operation auszuführen.",
    },
  },
  BACK_BUTTON: "Zurück zur Übersicht",
};
